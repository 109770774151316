import React, {useEffect, useState} from "react"
import { httpApiGateway } from "../../adapters/secondary/httpEshopApi";
import { AccountCreate, StaticContext } from "../../domain/interfaces";
import Page from "../../templates/page"
import { useLocation } from '@reach/router';
import queryString from 'query-string'

export default function Account(props: any) {

  const location = useLocation()
  const token = queryString.parse(location.search).id as string
  const api = new httpApiGateway()

  const [context, setContext] = useState<StaticContext | null>(null)
  const [confirm, setConfirm] = useState<'idle' | 'success' | 'error'>('idle')
  
  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
    api.confirmEmail(token).then((data) => {
      if (data.success) setConfirm('success')
      else setConfirm('error')
    })
  }, []) // given empty array useEffect run only once

  return context && <Page pageContext={context} hideBasket={false}>
    <div className="pt-20 md:py-24 flex flex-col items-center">

        { confirm === 'success' && <div className="mt-5 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-full md:w-96 mx-auto">
          <h1 className="text-3xl text-brand-primary mb-4"> Bravo ! 🎉 </h1>
            <p>
                Votre email a bien été activé ! Vous pouvez maintenant vous connecter
            </p>
            <a href="/signin" className="mt-5 w-40 text-center cursor-pointer bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-1 px-2 rounded text-md">
              Je me connecte
            </a>
          </div> 
        }
        {  confirm === 'error' && <div className="mt-5 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-full md:w-96 mx-auto">
          <h1 className="text-3xl text-brand-primary mb-4"> 🚧 Une erreur s'est produite </h1>
            <p>
                Nous sommes désolés, nous n'avons pas été en mesure d'activer votre compte
            </p> 
          </div> 
        }
    </div>
  </Page>
}
